import React, { useEffect, useContext } from 'react';
import * as Chat from 'twilio-chat';
import PropTypes from 'prop-types';
import { MessagesContext } from '../../providers/Messages';

function TwilioContainer({ children, user }) {
  const { dispatch } = useContext(MessagesContext);

  useEffect(() => {
    async function setupChat() {
      try {
        const client = await Chat.Client.create(user.token);

        const channels = await client.getSubscribedChannels();

        channels.items.forEach(async (channel) => {
          const channelUsers = await channel.getUserDescriptors();
          channelUsers.items.forEach((newUser) => newUser.subscribe());
        });

        client.on('messageAdded', (message) => {
          // BROWSER ALERT
          // CONFIRM MULTIPLE MATCHES ARENT ADDED TO UI

          dispatch({
            type: 'messageAdded',
            message: {
              matchId: message.channel.uniqueName,
              body: message.body,
              mediaUrl: (message.attributes || {}).mediaUrl,
              from: {
                id: message.author
              }
            }
          });
        });

        client.on('typingStarted', (member) => {
          if (member.identity !== user.id) {
            dispatch({
              type: 'typingStarted',
              matchId: member.channel.uniqueName
            });
          }
        });

        client.on('typingEnded', (member) => {
          if (member.identity !== user.id) {
            dispatch({
              type: 'typingEnded',
              matchId: member.channel.uniqueName
            });
          }
        });

        client.on('userUpdated', (member) => {
          const userId = member.user.identity;
          const { online } = member.user;
          dispatch({ type: 'updateOnline', userId, online });
        });
      } catch (err) {
        console.error(err);
      }
    }

    setupChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
}

TwilioContainer.propTypes = {
  children: PropTypes.any,
  user: PropTypes.object
};

export default TwilioContainer;
