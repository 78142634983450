import React from 'react';
import Parse from 'parse';
import { ToastAlert } from 'ustrive-ui';
import { toast } from 'react-toastify';

async function logIn(email = '', password = '') {
  try {
    const user = await Parse.User.logIn(email.toLowerCase(), password);
    window.analytics.identify(user.id);
    window.analytics.track('Logged In');
    return user;
  } catch (err) {
    throw err;
  }
}

async function logOut() {
  try {
    const response = await Parse.User.logOut();
    window.location = '/login';
    return response;
  } catch (err) {
    throw err;
  }
}

async function register(params) {
  try {
    const token = await Parse.Cloud.run(
      params.type === 'student' ? 'createStudent' : 'createMentor',
      params,
    );
    const user = await Parse.User.become(token);
    window.analytics.identify(user.id);
    window.analytics.track(
      'Registered',
      {
        userId: user.id,
        label: params.slug,
        category: `${
          params.type === 'student' ? 'Student' : 'Mentor'
        } Onboarding`,
      },
      {
        integrations: {
          All: false,
          'Google Analytics': true,
          'Google Tag Manager': true,
        },
      },
    );
    // window.dataLayer.push({
    //   event: 'event',
    //   eventProps: {
    //     category:
    //       params.type === 'student'
    //         ? 'Student Onboarding'
    //         : 'Mentor Onboarding',
    //     action: 'Registered',
    //     label: params.slug,
    //   },
    // });
    window.gtag('event', 'conversion', {
      send_to: 'AW-941450439/1Jk2CKnY04IDEMfJ9cAD',
    });
    return user;
  } catch (err) {
    throw err;
  }
}

async function run(endpoint, params) {
  try {
    const response = Parse.Cloud.run(endpoint, params);
    return response;
  } catch (err) {
    toast.error(
      <ToastAlert title="Request Failed" description={err.message} />,
    );
    throw err;
  }
}

function currentUser() {
  try {
    const user = Parse.User.current();
    return user;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

async function become(token) {
  try {
    if (!token) throw Error('No token.');
    const user = await Parse.User.become(token);
    window.analytics.identify(user.id);
    return user;
  } catch (err) {
    throw err;
  }
}

export {
  logIn, logOut, register, run, currentUser, become
};
