import React, { useState } from 'react';
import { CircleIcon, Divider } from 'ustrive-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import SocialSharing from '../SocialSharing';
import {
  ModalTitle,
  ModalDescription,
  UrlInput,
  CopyButton,
  ShareText,
} from './styled';

export function ReferralsImage({ user, size }) {
  return user.type === 'mentor' ? (
    <img
      src="https://s3.us-west-2.amazonaws.com/www.ustrive.com/branches/americanexpress/amex-square-logo.png"
      alt="American Express Logo"
      width={size || '102'}
      height={size || '102'}
      className="mb-3"
    />
  ) : (
    <CircleIcon size={83} icon="users-medical" margin="0 auto" />
  );
}

export function ReferralInput({ referralUrl, marginBottom }) {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(false);
    navigator.clipboard.writeText(referralUrl);
    setCopied(true);
  };

  return (
    <div className={`d-flex ${marginBottom || 'mb-3'}`}>
      <UrlInput value={referralUrl.replace('https://', '')} disabled />
      <CopyButton type="button" onClick={onCopy}>
        <FontAwesomeIcon icon={faCopy} color="#FFFFFF" />
        {copied ? 'Copied!' : 'Copy'}
      </CopyButton>
    </div>
  );
}

export const getReferralUrl = (user) => {
  const PUBLIC_URL = process.env.PUBLIC_URL || 'https://app.ustrive.com';

  return `${PUBLIC_URL}/${
    user.type === 'student' ? 'students' : 'mentors/amexrefer'
  }?r=${user.id}`;
};

function ReferralsInfo({ user }) {
  const referralUrl = getReferralUrl(user);

  return (
    <div className="text-center">
      <ReferralsImage user={user} />
      <ModalTitle>Refer your friends today!</ModalTitle>
      {user.type === 'mentor' ? (
        <ModalDescription>
          Want to invite friends to volunteer as a UStrive mentor? Now you can!
          Share this link with friends, and American Express will cover their
          mentor background check fee when they register.
        </ModalDescription>
      ) : (
        <ModalDescription>
          Invite your friends to UStrive and earn entries into our
          {' '}
          <strong>$1,000 raffle</strong>
          ! Every friend that you invite will earn
          you 1 entry as soon as they verify their phone. The more friends you
          invite, the more chances you have to win!
        </ModalDescription>
      )}
      <ReferralInput referralUrl={referralUrl} />
      {user.type === 'mentor' ? (
        <ModalDescription className="mb-0">
          This is a limited time offer. The campaign will close on 12/31/2021 or
          when the first 2,500 referred friends have registered, whichever comes
          first.
        </ModalDescription>
      ) : (
        <ModalDescription className="mb-0">
          This is a limited-time offer. The campaign will end on 11/1/21.
        </ModalDescription>
      )}
      <Divider margin="42" mobileMargin="20" />
      <div className="row">
        <div className="col-md-6 text-left">
          <ShareText>Share your referral code on:</ShareText>
        </div>
        <div className="col-md-6">
          <SocialSharing url={referralUrl} type={user.type} />
        </div>
      </div>
    </div>
  );
}

export default ReferralsInfo;
