import React from 'react';
import Loadable from 'react-loadable';
import PageLoader from '../Elements/PageLoader';

const Video = Loadable({
  loader: () => import(/* webpackChunkName: "Video" */ './Video'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ '../Auth/Login'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Mentors = Loadable({
  loader: () => import(/* webpackChunkName: "Mentors" */ '../Auth/Mentors'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Students = Loadable({
  loader: () => import(/* webpackChunkName: "Students" */ '../Auth/Students'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Forgot = Loadable({
  loader: () => import(/* webpackChunkName: "Forgot" */ '../Auth/Forgot'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Reset = Loadable({
  loader: () => import(/* webpackChunkName: "Reset" */ '../Auth/Reset'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Terms = Loadable({
  loader: () => import(/* webpackChunkName: "Terms" */ '../Auth/Terms'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Privacy = Loadable({
  loader: () => import(/* webpackChunkName: "Privacy" */ '../Auth/Privacy'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Confirm = Loadable({
  loader: () => import(/* webpackChunkName: "Confirm" */ '../Auth/Confirm'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Unsubscribe = Loadable({
  loader: () => import(/* webpackChunkName: "Unsubscribe" */ '../Auth/Unsubscribe'),
  loading: () => <PageLoader />,
  delay: 500,
});

const College = Loadable({
  loader: () => import(/* webpackChunkName: "College" */ '../Dashboard/College'),
  loading: () => <PageLoader />,
  delay: 500,
});

/* Authenticated Routes */

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ '../Dashboard/Home'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Community = Loadable({
  loader: () => import(/* webpackChunkName: "Community" */ '../Dashboard/Community'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Suggestions = Loadable({
  loader: () => import(/* webpackChunkName: "Suggestions" */ '../Dashboard/Mentors'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Mentor = Loadable({
  loader: () => import(/* webpackChunkName: "Mentor" */ '../Dashboard/Mentor'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Goals = Loadable({
  loader: () => import(/* webpackChunkName: "Goals" */ '../Dashboard/Goals'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Goal = Loadable({
  loader: () => import(/* webpackChunkName: "Goal" */ '../Dashboard/Goal'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Messages = Loadable({
  loader: () => import(/* webpackChunkName: "Messages" */ '../Dashboard/Messages'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Module = Loadable({
  loader: () => import(/* webpackChunkName: "Module" */ '../Dashboard/Module'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Documents = Loadable({
  loader: () => import(/* webpackChunkName: "Documents" */ '../Dashboard/Documents'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Colleges = Loadable({
  loader: () => import(/* webpackChunkName: "Colleges" */ '../Dashboard/Colleges'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Settings = Loadable({
  loader: () => import(/* webpackChunkName: "Settings" */ '../Dashboard/Settings'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Guides = Loadable({
  loader: () => import(/* webpackChunkName: "Guides" */ '../Dashboard/Guides'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "Contact" */ '../Dashboard/Contact'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Help = Loadable({
  loader: () => import(/* webpackChunkName: "Help" */ '../Dashboard/Help'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Topic = Loadable({
  loader: () => import(/* webpackChunkName: "Topic" */ '../Dashboard/Topic'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Scholarship = Loadable({
  loader: () => import(/* webpackChunkName: "Scholarship" */ '../Dashboard/Scholarship'),
  loading: () => <PageLoader />,
  delay: 500,
});

const Feedback = Loadable({
  loader: () => import(/* webpackChunkName: "Feedback" */ '../Dashboard/Feedback'),
  loading: () => <PageLoader />,
  delay: 500,
});

// const Opportunities = Loadable({
//   loader: () => import(
//     /* webpackChunkName: "Feedback" */ '../Dashboard/Opportunities'
//   ),
//   loading: () => <PageLoader />,
//   delay: 500,
// });

// const Oppurtunity = Loadable({
//   loader: () => import(
//     /* webpackChunkName: "Feedback" */ '../Dashboard/Oppurtunity'
//   ),
//   loading: () => <PageLoader />,
//   delay: 500,
// });

const Referrals = Loadable({
  loader: () => import(/* webpackChunkName: "Feedback" */ '../Dashboard/Referrals'),
  loading: () => <PageLoader />,
  delay: 500,
});

export {
  Video,
  Login,
  Mentors,
  Students,
  Forgot,
  Reset,
  Terms,
  Privacy,
  Confirm,
  Unsubscribe,
  College,
  Home,
  Community,
  Suggestions,
  Mentor,
  Goals,
  Goal,
  Messages,
  Module,
  Documents,
  Colleges,
  Settings,
  Guides,
  Contact,
  Help,
  Topic,
  Scholarship,
  Feedback,
  Referrals,
  // Opportunities,
  // Oppurtunity
};
