import React, { useState } from 'react';
import { Dropdown, ToastAlert } from 'ustrive-ui';
import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import { StyledCard as Card, QuestionLabel } from '../styled';
import c from './content.json';

function EthnicityStep({ user, onSave, goBack }) {
  const [ethnicity, setEthnicity] = useState(user.demographics.ethnicity || '');
  const nextStep = (params) => {
    if (!params.demographics.ethnicity) {
      return toast.error(
        <ToastAlert
          title="Ethnicity Selction"
          description="Please select an option before proceeding to the next step."
        />,
      );
    }
    onSave(params);
  };
  const onChange = (value) => {
    setEthnicity(value);
    nextStep({
      demographics: {
        ...user.demographics,
        ethnicity: value,
      },
    });
  };
  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>{c.title}</QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <Dropdown value={ethnicity} onChange={onChange}>
                  <option value="" disabled>
                    Choose One
                  </option>
                  <option value="black">Black or African American</option>
                  <option value="aian">American Indian/Alaska Native</option>
                  <option value="asian">Asian</option>
                  <option value="asian_pacific_islander">
                    Asian/Pacific Islander
                  </option>
                  <option value="hispanic">Hispanic</option>
                  <option value="middle_eastern">
                    Middle Eastern or North African
                  </option>
                  <option value="two_or_more">Two or more races</option>
                  <option value="nhpi">Native Hawaiian/Pacific Islander</option>
                  <option value="white">White</option>
                  <option value="unknown">Prefer Not to Say</option>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {user.demographics.ethnicity && (
        <NavButtons
          goBack={goBack}
          nextStep={() => nextStep({ demographics: { ethnicity } })}
        />
      )}
    </>
  );
}

export default EthnicityStep;
