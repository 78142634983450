import React, { useState } from 'react';
import {
  Dropdown,
  ToastAlert
} from 'ustrive-ui';
import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import {
  StyledCard as Card,
  QuestionLabel,
} from '../styled';
import c from './content.json';

function GenderStep({ user, onSave, goBack }) {
  const [gender, setGender] = useState(user.demographics.gender || '');
  const nextStep = (params) => {
    if (!params.demographics.gender) {
      return toast.error(
        <ToastAlert
          title="Gender Selection"
          description="Please select an option before proceeding to the next step."
        />
      );
    }
    onSave(params);
  };
  const onChange = (value) => {
    setGender(value);
    nextStep({
      demographics: {
        ...user.demographics,
        gender: value
      }
    });
  };
  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>{c.title}</QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <Dropdown
                  value={user.demographics.gender}
                  onChange={onChange}
                >
                  <option value="" disabled>
                    Choose One
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="nonbinary">Nonbinary</option>
                  <option value="other">Other</option>
                  <option value="unknown">Prefer Not to Say</option>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {
        user.demographics.gender && (
          <NavButtons goBack={goBack} nextStep={() => nextStep({ demographics: { gender } })} />
        )
      }
    </>
  );
}

export default GenderStep;
