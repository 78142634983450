import { useMutation, useQueryClient } from '@tanstack/react-query';
import { run } from '../helpers/parse';

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (params) => {
      await run('updateUser', params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user']);
      },
    },
  );
};

export default useUpdateUser;
