import React, { useState } from 'react';
import { CircleIcon, ToastAlert } from 'ustrive-ui';
import { toast } from 'react-toastify';
import NavButtons from '../NavButtons';
import { QuestionLabel, AnswerButton, StyledCard as Card } from '../styled';
import c from './content.json';

function ObjectiveStep({ user, onSave }) {
  const [objective, setObjective] = useState(user.objective);

  const onClick = (val) => {
    if (!val) {
      return toast.error(
        <ToastAlert
          title="Make Selection"
          description="Please let us know what you're looking for help with before proceeding!"
        />,
      );
    }
    setObjective(val);
    onSave({
      objective: val,
    });
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>
              Hey {user.firstName}! {c.label}
            </QuestionLabel>
            <div className="row">
              {c.objectives.map((o) => (
                <div key={o.value} className="col-md-6 mb-3">
                  <AnswerButton
                    dataCy={`objective-btn-${o.value}`}
                    onClick={() => onClick(o.value)}
                  >
                    {o.value === objective ? (
                      <CircleIcon icon="check" size={38} />
                    ) : (
                      <CircleIcon
                        icon={o.icon}
                        size={38}
                        color="rgba(49, 105, 247, 0.1)"
                        iconColor="#0C46DB"
                      />
                    )}
                    {o.label}
                  </AnswerButton>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
      {user.objective && <NavButtons nextStep={() => onSave({ objective })} />}
    </>
  );
}

export default ObjectiveStep;
