import styled from 'styled-components';
import { Button, Card, CollegeSelect } from 'ustrive-ui';
import fonts from '../../../../helpers/fonts';

export const SkipButton = styled(Button)`
  &&& {
    border: 0px !important;
    color: #0c46db;
    text-decoration: underline;
    font-size: 14px;
    line-height: 20px;
    display: block;
    padding-left: 0px;
  }
`;

export const QuestionLabel = styled.p`
  &&& {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 28px;
    color: #29345c;
    font-family: ${fonts.fontFamilies.SofiaPro};
    font-weight: ${fonts.fontWeights.medium};
    font-style: ${fonts.fontStyles.normal};
  }
`;

export const AccordionSection = styled.div`
  padding: 0px 14px 10px 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  ${(props) =>
    props.complete &&
    !props.editing &&
    `
  margin: 0px 10px;
  cursor: pointer;
  padding: 10px 14px;
  background-color: #0C46DB;
  p {
    color: #ffffff;
    margin: 3px 0px 0px 10px;
  }
  `}
`;

export const Icon = styled.img`
  width: 60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 50%;
  margin: 10px auto;
  display: block;
`;

export const AnswerButton = styled.button.attrs((props) => ({
  'data-cy': props.dataCy,
}))`
  background: #ffffff;
  border: 1px solid #e2eaf7;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 18px 20px;
  width: 100%;
  text-align: left;
  font-style: ${fonts.fontStyles.normal};
  font-weight: ${fonts.fontWeights.medium};
  font-size: 14px;
  line-height: 22px;
  color: #29345c;
  display: flex;
  align-items: center;
  div {
    margin-right: 10px;
  }
`;

export const StyledCard = styled(Card)`
  &&& {
    margin-bottom: 32px;
    box-shadow: none;
  }
`;

export const StyledCollegeSelect = styled(CollegeSelect)`
  div[class$='control'] {
    height: 60px;
    background: red;
  }
`;

export const SwitchText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: rgb(86, 100, 138);
  font-family: ${fonts.fontFamilies.SofiaPro};
  margin-left: 10px;
  margin-top: -5px;
`;
