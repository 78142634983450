import React, { useState } from 'react';
import {
  Dropdown,
  ToastAlert
} from 'ustrive-ui';
import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import {
  StyledCard as Card,
  QuestionLabel,
} from '../styled';
import c from './content.json';

const formatBoolean = (bool) => {
  if (bool !== undefined) {
    if (bool) {
      return 'Yes';
    }
    return 'No';
  }
  return '';
};

function GenerationStep({ user, onSave, goBack }) {
  const [generation, setGeneration] = useState(user.demographics.generation);

  const nextStep = (params) => {
    if (params.demographics.generation === undefined || params.demographics.generation === '') {
      return toast.error(
        <ToastAlert
          title="First Generation Status"
          description="Please indicate your first generation status."
        />
      );
    }
    onSave(params);
  };

  const onChange = (value) => {
    setGeneration(value === 'No');
    nextStep({
      demographics: {
        ...user.demographics,
        generation: value === 'No',
      }
    });
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>
              {c.title}
            </QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <Dropdown
                  value={formatBoolean(generation === undefined ? generation : !generation)}
                  onChange={onChange}
                >
                  <option disabled value="">
                    Choose One
                  </option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {
        user.demographics.generation !== undefined && (<NavButtons goBack={goBack} nextStep={() => nextStep({ demographics: { generation } })} />)
      }
    </>
  );
}

export default GenerationStep;
