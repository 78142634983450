import React, { useEffect, useState, useCallback } from 'react';
import PageLoader from '../components/Elements/PageLoader';
import { currentUser, logOut } from '../helpers/parse';
import useGetUser from '../hooks/useGetUser';
import { clarity } from 'react-microsoft-clarity';

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

function UserProvider(props) {
  const [user, updateUser] = useState(null);
  const { data, error } = useGetUser();

  const getUser = useCallback(() => {
    return user;
  }, [user]);

  useEffect(() => {
    if (data) updateUser((prev) => ({ ...data }));
    if (error) {
      if (currentUser()) logOut();
      window.location = '/login';
    }
  }, [data, error]);

  // start the microsoft clarity session
  useEffect(() => {
    if (user?.id) {
      if (clarity.hasStarted()) console.log('Clarify has started');
      clarity.identify(user?.id, {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      });
    }
  }, [user]);

  const { children } = props;

  return user ? (
    <UserContext.Provider
      value={{
        user,
        updateUser,
        getUser,
      }}
    >
      {children}
    </UserContext.Provider>
  ) : (
    <PageLoader />
  );
}

export { UserConsumer, UserContext, UserProvider };
