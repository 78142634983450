import styled from 'styled-components';
import fonts from '../../../helpers/fonts';

export const ModalTitle = styled.h2`
  color: #1d2b42;
  font-size: 20px;
  line-height: 28px;
  font-family: ${fonts.fontFamilies.SofiaPro};
  font-weight: ${fonts.fontWeights.medium};
  font-style: ${fonts.fontStyles.normal};
  margin: 15px 0px 10px 0px;
`;

export const ModalDescription = styled.p`
  color: #56648a;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0px;
`;

export const UrlInput = styled.input`
  background: #ffffff;
  border: 1px solid #d1d8e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 22px;
  color: #8892ab;
  flex: 1;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  border-right: 0px;
`;

export const CopyButton = styled.button`
  background: #0c46db;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border: 0px;
  box-shadow: none;
  padding: 15px 30px;
  font-weight: bold;
  svg {
    margin-right: 5px;
  }
`;

export const ShareText = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #29345c;
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;
