import React, { useState } from 'react';
import Parse from 'parse';
import { register } from '../helpers/parse';

const AuthContext = React.createContext();
const AuthConsumer = AuthContext.Consumer;

function AuthProvider(props) {
  const [authenticated, setAuthenticated] = useState(Parse.User.current());
  const [hideNavigation, setHideNavigation] = useState(false);
  const [branch, setBranch] = useState({});
  const { children } = props;

  async function registerStudent(params) {
    try {
      await register({
        ...params,
        type: 'student',
        slug: branch.slug,
        registerURL: localStorage.getItem('registerURL'),
        referralId: localStorage.getItem('referralId'),
      });

      window.analytics.track(
        'Registered Student',
        {},
        {
          integrations: {
            All: false,
            'Facebook Pixel': true,
            'Google Tag Manager': true,
          },
        },
      );

      setAuthenticated(true);
    } catch (err) {
      throw err;
    }
  }

  async function registerMentor(params) {
    try {
      await register({
        ...params,
        type: 'mentor',
        slug: branch.slug,
        registerURL: localStorage.getItem('registerURL'),
        referralId: localStorage.getItem('referralId'),
      });

      window.analytics.track(
        'Registered Mentor',
        {},
        {
          integrations: {
            All: false,
            'Facebook Pixel': true,
          },
        },
      );
      setAuthenticated(true);
    } catch (err) {
      throw err;
    }
  }

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        registerStudent,
        registerMentor,
        setBranch,
        hideNavigation,
        setHideNavigation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthConsumer, AuthProvider };
