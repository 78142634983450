import React from 'react';
import PropTypes from 'prop-types';
import ReferralModal from '../ReferralModal';

function Popups({ user, referralCampaign }) {
  if (referralCampaign) {
    return <ReferralModal user={user} />;
  }
  return null;
}

Popups.propTypes = {
  user: PropTypes.object,
};

export default Popups;
