import React, { useState } from 'react';
import { AvatarUpload, BioField, Divider, ToastAlert } from 'ustrive-ui';
//import { run } from '../../../../helpers/parse';

import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import { StyledCard as Card } from '../styled';
import c from './content.json';

function BioStep({ user, onSave, goBack }) {
  const [bio, setBio] = useState(
    user.bio || `Hi, I'm ${user.firstName}, and I’m looking for...`,
  );
  const [avatar, setAvatar] = useState(user.avatar || '');

  async function onUpload(value) {
    try {
      setAvatar(value);
    } catch (err) {
      toast.error(<ToastAlert description={err.message} />);
    }
  }

  const nextStep = (params) => {
    if (!params.info.avatar) {
      return toast.error(
        <ToastAlert
          title="Avatar"
          description="Please upload your avatar before proceeding to the next step."
        />,
      );
    }
    if (
      !params.info.bio ||
      (user?.type === 'student'
        ? params.info.bio.length < 250
        : params.info.bio.length < 1)
    ) {
      return toast.error(
        <ToastAlert
          title="Bio"
          description="Your bio does not meet the minimum character requirements."
        />,
      );
    }

    onSave({ avatar, bio });
  };
  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <AvatarUpload url={avatar} onUpload={onUpload} />
                <Divider margin={20} />
                <BioField
                  bio={bio}
                  updateBio={setBio}
                  user={user}
                  description={c.description}
                  placeholder={`Hi, I'm ${user.firstName}, and I’m looking for help with...`}
                  minLength={user?.type === 'student' ? 250 : 1}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <NavButtons
        goBack={goBack}
        nextStep={() => nextStep({ info: { avatar, bio } })}
      />
    </>
  );
}

export default BioStep;
