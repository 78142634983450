import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faFacebookMessenger,
} from '@fortawesome/free-brands-svg-icons';

import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

const ShareIcons = styled.div`
  button {
    margin-left: 20px;
  }
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

function SocialSharing({ url, type, description }) {
  const typeTitle = type === 'student'
    ? "Join UStrive today and find a mentor to help with college or career choices! It's 100% free."
    : "Join UStrive and become a mentor for high school & college students. It's 100% free!";
  return (
    <ShareIcons className="d-flex align-items-center">
      <FacebookShareButton url={url} quote={typeTitle}>
        <FontAwesomeIcon icon={faFacebook} color="#4267B2" size="lg" />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        appId="404976840029833"
        redirectUri=""
        url={url}
      >
        <FontAwesomeIcon icon={faFacebookMessenger} color="#006AFF" size="lg" />
      </FacebookMessengerShareButton>
      <LinkedinShareButton
        url={url}
        title={typeTitle}
        summary={description}
        source=""
      >
        <FontAwesomeIcon icon={faLinkedin} color="#2867B2" size="lg" />
      </LinkedinShareButton>
      <TwitterShareButton
        url={url}
        title={typeTitle}
        via="Strive4C"
        hashtags={['ustrive', 'mentors']}
        related={['Strive4C']}
      >
        <FontAwesomeIcon icon={faTwitter} color="#1DA1F2" size="lg" />
      </TwitterShareButton>
    </ShareIcons>
  );
}

SocialSharing.defaultProps = {
  description:
    'UStrive connects high school & college students with a mentor to help guide them through the college & career process, using our online mentoring platform.',
};

SocialSharing.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default SocialSharing;
