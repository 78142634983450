import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import ReactModal from 'react-modal';
import ReferralsInfo from '../ReferralsInfo';

ReactModal.setAppElement('#root');

const StyledModal = styled(ReactModal)`
  && .modal-dialog {
    width: 555px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background: none;
  color: #8892ab;
  box-shadow: none;
  border: 0px;
`;

function ReferralModal({ user }) {
  // { user } as a parameter
  const [open, setOpen] = useState(!localStorage.getItem('hideReferralModal'));

  useEffect(() => {
    if (!open) localStorage.setItem('hideReferralModal', true);
  }, [open]);

  return (
    <StyledModal
      isOpen={open}
      contentLabel="Refer Your Friends"
      className="react-modal"
      overlayClassName="react-modal-overlay"
    >
      <div className="modal-dialog modal-dialog-sm">
        <div className="modal-content text-center">
          <CloseButton data-cy="close-referrals" onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </CloseButton>
          <ReferralsInfo user={user} />
        </div>
      </div>
    </StyledModal>
  );
}

export default ReferralModal;
