import styled from 'styled-components';
import fonts from '../../../helpers/fonts';

export const SwitchText = styled.span`
font-size: 14px;
line-height: 22px;
color: rgb(86, 100, 138);
font-family: ${fonts.fontFamilies.SofiaPro};
margin-left: 10px;
margin-top: -5px;
`;

export const empty = '';
