import React, { useState } from 'react';
import {
  Dropdown,
  ToastAlert
} from 'ustrive-ui';
import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import {
  StyledCard as Card,
  QuestionLabel,
} from '../styled';
import c from './content.json';

function LunchStep({ user, onSave, goBack }) {
  const [lunch, setLunch] = useState(user.demographics.lunch);

  const nextStep = (params) => {
    if (params.demographics.lunch === undefined || params.demographics.lunch === '') {
      return toast.error(
        <ToastAlert
          title="Lunch Status"
          description="Please indicate your lunch status."
        />
      );
    }
    onSave(params);
  };

  const onChange = (value) => {
    setLunch(value);
    nextStep({
      demographics: {
        ...user.demographics,
        lunch: value
      }
    });
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>
              {c.title[user.level]}
            </QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <Dropdown
                  value={lunch}
                  onChange={onChange}
                >
                  <option disabled value="">
                    Choose One
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="not_sure">Not Sure</option>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {
        user.demographics.lunch !== undefined && (<NavButtons goBack={goBack} nextStep={() => nextStep({ demographics: { lunch } })} />)
      }
    </>
  );
}

export default LunchStep;
