import React, { useState } from 'react';
import { ToastAlert } from 'ustrive-ui';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import NavButtons from '../NavButtons';
import {
  SkipButton,
  QuestionLabel,
  StyledCard as Card,
  StyledCollegeSelect as CollegeSelect,
} from '../styled';
import c from './content.json';

function CollegesStep({ user, onSave, goBack }) {
  const [colleges, setColleges] = useState(user.colleges);

  const onChange = (value = []) => {
    if (isEmpty(value)) {
      return toast.error(
        <ToastAlert
          title="Make Selection(s)"
          description="Please complete this step before proceeding!"
        />,
      );
    }
    setColleges(value);
  };

  const nextStep = (params) => {
    if (!params.noCollege && isEmpty(params.colleges)) {
      return toast.error(
        <ToastAlert
          title="Make Selection(s)"
          description="Please complete this step before proceeding!"
        />,
      );
    }
    onSave(params);
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>{c.title[user.level]}</QuestionLabel>
            <div className="row">
              {user.level === 'High School' ? (
                <div className="col-md-12">
                  <CollegeSelect multi value={colleges} onChange={onChange} />
                  <SkipButton
                    dataCy="no-colleges-btn"
                    width="auto"
                    onClick={() => nextStep({ noCollege: true })}
                  >
                    {c.notSure}
                  </SkipButton>
                </div>
              ) : (
                <div className="col-md-12">
                  <CollegeSelect
                    value={colleges[0]}
                    onChange={(val) =>
                      nextStep({
                        colleges: [val],
                      })
                    }
                  />
                  {(user.objective === 'internships' ||
                    user.objective === 'careers') && (
                    <SkipButton
                      dataCy="no-colleges-btn"
                      onClick={() => nextStep({ noCollege: true })}
                      width="auto"
                    >
                      {c.noCollege}
                    </SkipButton>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
      <NavButtons goBack={goBack} nextStep={() => nextStep({ colleges })} />
    </>
  );
}

export default CollegesStep;
