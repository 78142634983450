import fonts from "./fonts";

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const formatPhoneNumber = (s = '') => {
  const s2 = `${s.substring(s.length - 10)}`.replace(/\D/g, '');
  const m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : `(${m[1]}) ${m[2]}-${m[3]}`;
};

const toggleType = (type) => {
  if (!type) return '';
  return type === 'student' ? 'mentor' : 'student';
};

const graphColors = [
  '#9FABC3',
  '#ADB8CC',
  '#C0C9DA',
  '#D5DDEC',
  '#E4E9F4',
  '#DCEDFF',
];

const graphLegend = {
  fullWith: true,
  reverse: true,
  labels: {
    fontColor: '#56648A',
    fontFamily: fonts.fontFamilies.SofiaPro,
    /*
        generateLabels: (chart) => {
            console.log(chart);
            return chart.data.labels;
        }
        */
  },
};

const graphOptions = {
  maintainAspectRatio: false,
};

const educationMajors = (arr) =>
  arr.map((record) => record.majors.map((major) => major.label)).flat();

const goalStatusLabels = {
  0: 'Not Started',
  1: 'In Progress',
  2: 'Needs Review',
  3: 'In Review',
  4: 'Completed',
};

export {
  capitalize,
  formatPhoneNumber,
  toggleType,
  graphColors,
  graphLegend,
  graphOptions,
  educationMajors,
  goalStatusLabels,
};
