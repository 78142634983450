import React, { useState } from 'react';
import { InputField, ToastAlert } from 'ustrive-ui';
import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import { StyledCard as Card, QuestionLabel } from '../styled';
import c from './content.json';

function BirthdateStep({ user, onSave, goBack }) {
  const [birthdate, setBirthdate] = useState(user.info.birthdate || '');
  const nextStep = (params) => {
    if (!params.birthdate) {
      return toast.error(
        <ToastAlert
          title="Birthdate"
          description="Please provide your birthdate before proceeding to the next step."
        />,
      );
    }
    onSave(params);
  };
  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>{c.title}</QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <InputField
                  dataCy="birthdate"
                  id="birthdate"
                  mask="11/11/1111"
                  value={birthdate}
                  onChange={setBirthdate}
                  placeholder="12/13/2004"
                  type="text"
                />
              </div>
              {/* {birthdate.replace('_', '').length === 10 && (

              )} */}
            </div>
          </div>
        </div>
      </Card>
      <NavButtons goBack={goBack} nextStep={() => nextStep({ birthdate })} />
    </>
  );
}

export default BirthdateStep;
