import React, { useState } from 'react';
import { ToastAlert, PhoneInput } from 'ustrive-ui';

import { toast } from 'react-toastify';

import NavButtons from '../NavButtons';
import { StyledCard as Card, QuestionLabel } from '../styled';
import c from './content.json';

function PhoneStep({ user, onSave, goBack }) {
  const [phone, setPhone] = useState(user.phone || '');
  const nextStep = async (params) => {
    if (!params.phone) {
      return toast.error(
        <ToastAlert
          title="Phone Number"
          description="Please provide your mobile phone number before finishing up."
        />,
      );
    }

    onSave(params);
  };
  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>{c.title}</QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <PhoneInput
                  value={phone}
                  onChange={setPhone}
                  country={(
                    (user.info.country || {}).code || 'US'
                  ).toLowerCase()}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <NavButtons goBack={goBack} nextStep={() => nextStep({ phone })} />
    </>
  );
}

export default PhoneStep;
