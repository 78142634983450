const fonts = {
  fontFamilies: {
    SofiaPro: 'sofia-pro, sans-serif',
  },
  fontStyles: {
    normal: 'normal',
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    medium: 500,
    bold: 700,
    black: 900,
  }
};

export default fonts;
