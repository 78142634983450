import React, { useState } from 'react';
import { MajorSelect, ToastAlert } from 'ustrive-ui';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';

import NavButtons from '../NavButtons';
import { SkipButton, QuestionLabel, StyledCard as Card } from '../styled';
import c from './content.json';

function MajorsStep({ user, onSave, goBack }) {
  const [majors, setMajors] = useState(user.majors);

  const nextStep = (params) => {
    if (isEmpty(params.majors)) {
      return toast.error(
        <ToastAlert
          title="Make Selection(s)"
          description="Please complete this step before proceeding!"
        />,
      );
    }
    onSave(params);
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>{c.title[user.level]}</QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                <MajorSelect value={majors} multi onChange={setMajors} />
                {user.level === 'High School' && (
                  <SkipButton
                    dataCy="no-majors-btn"
                    width="auto"
                    onClick={() =>
                      nextStep({
                        majors: [{ value: 'MuWiYEvxJD' }],
                      })
                    }
                  >
                    {c.notSure}
                  </SkipButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <NavButtons goBack={goBack} nextStep={() => nextStep({ majors })} />
    </>
  );
}

export default MajorsStep;
