import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Sidebar, SidebarItem, SidebarDivider } from 'ustrive-ui';
import { UserConsumer } from '../../providers/User';
import fonts from '../../helpers/fonts';

const SidebarPadding = styled.div`
  padding: 0px 20px;
`;

const SidebarText = styled.p`
  font-family: ${fonts.fontFamilies.SofiaPro};
  font-weight: ${fonts.fontWeights.light};
  font-style: ${fonts.fontStyles.normal};
  color: #b8c8e2;
  font-size: ${(props) => props.fontSize}px;
`;

const StyledLink = styled.a`
  color: #b8c8e2;
`;

function MainMenu({ displayMenu }) {
  return (
    <UserConsumer>
      {({ user }) => (
        <Sidebar displayMenu={displayMenu}>
          <SidebarItem title="Dashboard" icon="home" link="/dashboard" />

          {user?.type === 'student' &&
            user?.matches?.length === 0 &&
            !user?.branch?.manualMatching && (
              <SidebarItem
                title="Mentors"
                icon="user-friends"
                link="/mentors"
              />
            )}

          {user?.type === 'student' && user?.matches?.length > 0 && (
            <SidebarItem title="Messages" icon="comments" link="/messages" />
          )}

          {(user?.type === 'student' || user?.config?.verified) && (
            <>
              <SidebarItem title="Goals" icon="flag" link="/goals" />
              <SidebarItem
                title="Colleges"
                icon="university"
                link="/colleges"
              />
              <SidebarItem
                title="Documents"
                icon="file-alt"
                link="/documents"
              />
              <SidebarItem title="Guides" icon="book" link="/guides" />
            </>
          )}

          {((user?.config || {})?.verified || user?.matches?.length > 0) && (
            <SidebarItem
              title="Community"
              icon="globe-americas"
              link="/community"
            />
          )}

          <SidebarPadding>
            <SidebarDivider margin={20} />
          </SidebarPadding>
          <SidebarItem title="Settings" icon="cog" link="/settings" />
          <SidebarItem title="Help" icon="question-circle" link="/help" />
          <SidebarItem title="Contact Us" icon="envelope" link="/contact" />

          <SidebarPadding>
            <SidebarDivider margin={20} />
          </SidebarPadding>

          <SidebarPadding>
            <SidebarText fontSize="14">
              UStrive is powered by the Deloitte Foundation.
            </SidebarText>
            <SidebarText fontSize="12">
              You can learn more about the Deloitte Foundation{' '}
              <StyledLink
                href="https://www2.deloitte.com/us/en/pages/about-deloitte/topics/deloitte-foundation.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </StyledLink>
              .
            </SidebarText>
          </SidebarPadding>
        </Sidebar>
      )}
    </UserConsumer>
  );
}

MainMenu.propTypes = {
  displayMenu: PropTypes.string.isRequired,
};

export default MainMenu;
