import { useQuery } from '@tanstack/react-query';
import { run } from '../helpers/parse';

const useGetUser = () => {
  const { isLoading, data, error } = useQuery(
    ['user'],
    async () => await run('getUser'),
  );
  return { isLoading, data, error };
};

export default useGetUser;
