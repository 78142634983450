import React, { useState, useMemo } from 'react';
import { InputField, ToastAlert, Dropdown } from 'ustrive-ui';
import { toast } from 'react-toastify';
import countryList from 'react-select-country-list';

import NavButtons from '../NavButtons';
import { StyledCard as Card, QuestionLabel, SkipButton } from '../styled';
import c from './content.json';

function ZipStep({ user, onSave, goBack }) {
  const [zip, setZip] = useState(user.zip || '');
  const [international, setInternational] = useState(user.info.international);
  const [country, setCountry] = useState({
    code: '',
    label: '',
  });
  const countryOptions = useMemo(() => countryList().getData(), []);

  const nextStep = (params) => {
    if (!params.zip && !params.international) {
      return toast.error(
        <ToastAlert
          title="Zip Code"
          description="Please provide your zip code before proceeding."
        />,
      );
    }
    if (params.international && !params.country.code) {
      return toast.error(
        <ToastAlert
          title="Choose Country"
          description="Please select a country before proceeding."
        />,
      );
    }
    onSave(params);
  };

  const updateCountry = (val) => {
    const { value: code, label } = countryOptions.find(
      (option) => option.value === val,
    );
    setCountry({
      code,
      label,
    });
  };

  return (
    <>
      <Card padding="40px">
        <div className="row">
          <div className="col-md-12">
            <QuestionLabel>
              {international ? 'What country do you live in?' : c.title}
            </QuestionLabel>
            <div className="row">
              <div className="col-md-12">
                {international ? (
                  <Dropdown onChange={updateCountry} value={country.code}>
                    <option value="" disabled>
                      Choose Country
                    </option>
                    {countryOptions.map((countryValue) => (
                      <option
                        key={countryValue.value}
                        value={countryValue.value}
                      >
                        {countryValue.label}
                      </option>
                    ))}
                  </Dropdown>
                ) : (
                  <InputField
                    dataCy="zip-code"
                    id="zip"
                    mask="11111"
                    value={zip}
                    onChange={setZip}
                    placeholder="90210"
                    type="text"
                  />
                )}

                <SkipButton
                  dataCy="international-btn"
                  width="auto"
                  onClick={() => setInternational(!international)}
                >
                  {international
                    ? 'I live in the U.S.'
                    : 'I live outside of the U.S.'}
                </SkipButton>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <NavButtons
        goBack={goBack}
        nextStep={() => nextStep({ zip, international, country })}
      />
    </>
  );
}

export default ZipStep;
