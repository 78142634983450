import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ustrive-ui';

function NavButtons({ goBack, nextStep }) {
  return (
    <div className="row mb-4">
      <div className="col-md-4 offset-md-2 col-6">
        {goBack && (
          <Button
            dataCy="onboarding-prev-step"
            onClick={goBack}
            className="outline"
          >
            Previous
          </Button>
        )}
      </div>
      <div className="col-md-4 col-6 text-right">
        <Button
          dataCy="onboarding-next-step"
          onClick={nextStep}
          className="primary"
        >
          Next
        </Button>
      </div>
    </div>
  );
}

NavButtons.defaultProps = {
  goBack: undefined,
};

NavButtons.propTypes = {
  goBack: PropTypes.func,
  nextStep: PropTypes.func.isRequired,
};

export default NavButtons;
