import React from 'react';
import { run } from '../helpers/parse';

const MessagesContext = React.createContext();
const MessagesConsumer = MessagesContext.Consumer;

const initialState = {
  matchId: null,
  messages: [],
  typing: {},
  page: 0,
  body: '',
  more: false,
  loading: false,
  sending: false,
  error: '',
  online: {},
  mediaUrl: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'listMessages': {
      const newMessages = [...state.messages, ...action.messages];
      const list = state.page
        ? newMessages
        : action.messages;

      return {
        ...state,
        messages: list,
        loading: false,
        more: action.messages.length !== 0
      };
    }
    case 'messageAdded': {
      const messages = [...state.messages, action.message];

      return {
        ...state,
        messages
      };
    }
    case 'updateOnline':
      return {
        ...state,
        online: {
          ...state.online,
          [action.userId]: action.online
        }
      };
    case 'typingStarted':
      return {
        ...state,
        typing: {
          ...state.typing,
          [action.matchId]: true
        }
      };
    case 'typingEnded':
      return {
        ...state,
        typing: {
          ...state.typing,
          [action.matchId]: false
        }
      };
    case 'sendingStarted':
      return {
        ...state,
        sending: true,
        error: ''
      };
    case 'sendingEnded':
      return {
        ...state,
        sending: false,
        error: action.error,
        body: action.error ? state.body : initialState.body,
        mediaUrl: action.error ? state.mediaUrl : initialState.mediaUrl
      };
    case 'updateBody':
      return {
        ...state,
        body: action.body
      };
    case 'loadMore': {
      return {
        ...state,
        loading: true,
        page: action.page
      };
    }
    case 'clearMessages':
      return initialState;
    case 'updateMediaUrl': {
      return {
        ...state,
        mediaUrl: action.mediaUrl
      };
    }
    default:
      return state;
  }
};

function MessagesProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  async function listMessages(matchId, page) {
    if (!matchId) throw Error('Please provide a match ID.');

    try {
      dispatch({ type: 'loadMore', page });

      const messages = await run('messagesList', {
        matchId,
        page
      });

      dispatch({ type: 'listMessages', messages });
    } catch (err) {
      console.error(err);
    }
  }

  const createMessage = async (matchId) => {
    const messages = state.messages
    const previousMessage = messages[messages.length - 1]

    dispatch({ type: 'sendingStarted', sending: true });
    if (previousMessage.body.toLowerCase() === state.body.toLowerCase()) {
      return dispatch({
        type: 'sendingEnded',
        sending: false,
      });
    }

    if (state.body.length > 1500) {
      return dispatch({
        type: 'sendingEnded',
        sending: false,
        error: 'Please keep your messages below 1,500 characters.'
      });
    }

    try {
      await run('createMessage', { matchId, body: state.body, mediaUrl: state.mediaUrl });
      dispatch({ type: 'sendingEnded', sending: false });
    } catch (err) {
      dispatch({ type: 'sendingEnded', sending: true, error: err.message });
    }
  };

  const value = {
    state, dispatch, listMessages, createMessage
  };
  const { children } = props;
  return (
    <MessagesContext.Provider value={value}>
      {children}
    </MessagesContext.Provider>
  );
}

export { MessagesContext, MessagesConsumer, MessagesProvider };
