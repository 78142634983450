import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  LoadingIcon,
  PageGreeting,
  ProgressBar,
  SecondaryText,
  ToastAlert,
} from 'ustrive-ui';
import icon from '../../../../assets/images/icon.png';
import useUpdateUser from '../../../../hooks/useUpdateUser';
import BioStep from './BioStep';
import BirthdateStep from './BirthdateStep';
import CollegesStep from './CollegesStep';
import EthnicityStep from './EthnicityStep';
import GenderStep from './GenderStep';
import GenerationStep from './GenerationStep';
import GraduationStep from './GraduationStep';
import LunchStep from './LunchStep';
import MajorsStep from './MajorsStep';
import ObjectiveStep from './ObjectiveStep';
import PhoneStep from './PhoneStep';
import ZipStep from './ZipStep';
import c from './content.json';
import { StyledCard as Card, Icon } from './styled';

const steps = [
  'objective',
  'colleges',
  'majors',
  'graduation',
  'generation',
  'lunch',
  'zip',
  'gender',
  'ethnicity',
  'birthdate',
  'bio',
  'phone',
];

const stepComponents = {
  objective: ObjectiveStep,
  colleges: CollegesStep,
  majors: MajorsStep,
  graduation: GraduationStep,
  generation: GenerationStep,
  lunch: LunchStep,
  zip: ZipStep,
  gender: GenderStep,
  ethnicity: EthnicityStep,
  birthdate: BirthdateStep,
  bio: BioStep,
  phone: PhoneStep,
};

const BASE_PROGRESS = 6;

function StudentOnboarding({ user }) {
  const [saving, setSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState('objective');

  const updateUser = useUpdateUser();

  const saveChanges = async (params) => {
    setSaving(true);
    try {
      updateUser.mutate(params);
      const nextStep = steps[steps.indexOf(step) + 1];
      setSaving(false);
      if (step === 'colleges' && user.level !== 'High School' && user.noMajor) {
        setStep(steps[steps.indexOf(step) + 2]);
      } else {
        if (nextStep) {
          return setStep(nextStep);
        }
      }
    } catch (err) {
      setSaving(false);
      toast.error(
        <ToastAlert title="Request Failed" description={err.message} />,
      );
    }
  };

  const goBack = () => {
    const index = steps.indexOf(step);
    setStep(steps[index - 1]);
  };

  useEffect(() => {
    setProgress(steps.indexOf(step) * 9 + BASE_PROGRESS);
  }, [step]);

  useEffect(() => {
    let stepIndex = 0;
    if (user.objective) {
      stepIndex++;
    }
    if (user.noCollege || user.colleges.length > 0) {
      stepIndex++;
    }
    if (
      (user.level !== 'High School' && user.noMajor) ||
      user.majors.length > 0
    ) {
      stepIndex++;
    }
    if (
      user.academics.graduation ||
      user.academics.collegeGraduation ||
      (user.level === 'College' && user.noCollege)
    ) {
      stepIndex++;
    }
    if (user.demographics.generation !== undefined) {
      stepIndex++;
    }
    if (user.demographics.lunch !== undefined) {
      stepIndex++;
    }
    if (user.zip || (user.info.international && user.info.country)) {
      stepIndex++;
    }
    if (user.demographics.gender) {
      stepIndex++;
    }
    if (user.demographics.ethnicity) {
      stepIndex++;
    }
    if (user.info.birthdate) {
      stepIndex++;
    }
    if (user.info.bio && user.info.avatar) {
      stepIndex++;
    }
    if (user.phone) {
      stepIndex++;
    }
    setStep(steps[stepIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-4">
      <div className="row mt-4">
        <div className="col-md-8 offset-md-2">
          <Icon src={icon} alt="UStrive Icon" />
          <PageGreeting title={c.title} tagline={c.tagline} />
          <div className="row">
            <div className="col-md-12 mt-3 mb-4">
              <ProgressBar height={14} width={progress} />
            </div>
          </div>
          {saving ? (
            <Card padding="40px">
              <div className="row">
                <div className="col-md-12">
                  <LoadingIcon color="#d9dce3" size={48} />
                </div>
              </div>
            </Card>
          ) : (
            <>
              {React.createElement(stepComponents[step], {
                user,
                onSave: saveChanges,
                goBack,
              })}
            </>
          )}

          <SecondaryText className="text-center">
            Any questions for us?{' '}
            <a href="mailto:support@ustrive.com">support@ustrive.com</a>
          </SecondaryText>
        </div>
      </div>
    </div>
  );
}

export default StudentOnboarding;
