import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Parse from 'parse';
import { ToastContainer } from 'react-toastify';
// import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './providers/Auth';

import 'ustrive-ui/src/assets/css/main.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';

Parse.initialize('ustrive');
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;

// TagManager.initialize({
//   gtmId: 'GTM-PG7MG9B',
// });

const trackPageView = (page) => {
  window.analytics.page(
    page,
    {},
    {
      integrations: {
        All: false,
        'Google Analytics': true,
        'Active Campaign': true,
        Mixpanel: false,
      },
    },
  );
  window.gtag('event', 'conversion', {
    send_to: 'AW-941450439/qDPyCJL7s4IDEMfJ9cAD',
  });
  // window.dataLayer.push({
  //   event: 'pageview',
  // });
};

trackPageView(window.location.pathname);

ReactDOM.render(
  <AuthProvider>
    <App />
    <ToastContainer />
  </AuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
